import forList from "@/mixin/forList";
import DictionaryEdit from "./DictionaryEdit.vue";
import { initialPB } from "./config";


export default {
    name: "dictionary",
    mixins: [forList],
    components: { DictionaryEdit },

    data() {
        return {
            SP_: {
                searchType: '',  // 类型
                searchCode: "",  // 字典编码
                searchName: "",  // 名称
                searchParentName: "",  // 父类名称
                searchState: '',
            },
            configurationTypeEnum: [],
            searchStateTypes: [
                { name: "请选择", value: '' },
                { name: "启用", value: 1 },
                { name: "禁用", value: 0 },
            ],
            isaddchild: false,//添加子集标识
            editingItem: null,
            setingItem: null,
        };
    },

    methods: {
        async getListParam() {
            const res = await this.$oxios({
                url: "/api/configuration/listParam",
                method: "post",
            });
            if (res.code === 2000) {

                this.configurationTypeEnum = res.data.configurationTypeEnum || []
                this.configurationTypeEnum.unshift({ name: "请选择", value: 0 },)
                this.SP_.searchType = this.SP_.searchType || 0
            }
        },

        async changeState(row) {
            console.log(row);
            console.log(row.stateB);
            const loading = this.$loading();
            try {
                const res = await this.$axios({
                    url: "/api/configuration/updateConfigViewState",
                    method: "post",
                    data: {
                        configid: row.id,
                        state: row.stateB ? 1 : 0,
                    }
                });
                if (res.code === 2000) {
                    this.fetchData()
                    loading.close();
                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("操作失败", reason);
                this.$message({
                    message: reason.msg || "操作失败",
                    type: "warning"
                });
                this.fetchData()
                loading.close();
            }
        },
        async fetchData() {
            console.log("fetching...");
            const loading = this.$loading();

            try {
                let data = { ...this.extractParams() }
                console.log(data);
                if (!data.searchType) {
                    delete data.searchType
                }
                const res = await this.$axios({
                    url: "/api/configuration/list",
                    method: "post",
                    data: data
                });



                if (res.code === 2000) {
                    this.total = res.data.count;
                    res.data.conlist.forEach(element => {
                        element.stateB = element.viewState == 1 ? true : false
                    });
                    const list = res.data.conlist;
                    this.tableData = list;
                    loading.close();
                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });

                loading.close();
            }
        },

        // 点击 "新增菜单"
        handleAddBtn() {
            this.editingItem = initialPB();
            this.isaddchild = false
        },

        // 点击表格行操作按钮: 编辑 
        handleEdit(data) {
            this.editingItem = data;
            this.isaddchild = false
        },

        // 点击表格行操作按钮: 添加子集
        handleAddChild(data) {
            this.editingItem = data;
            this.isaddchild = true
        },

        // 点击表格行操作按钮: 删除
        async handleDelete(data) {
            let loadingRef;
            try {
                await this.$confirm("确定要删除吗", "系统提示")
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/configuration/delConfig",
                    method: "post",
                    data: {
                        configid: parseInt(data.id)
                    }
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.fetchData();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        }
    },

    created() {
        this.getListParam()
        this.auth.save = this.$hasAuthFor("api/configuration/saveConfiguration"); //新增、编辑
        this.auth.delete = this.$hasAuthFor("api/configuration/delConfig"); //删除
        this.auth.addChild = this.$hasAuthFor("api/configuration/addChildConfig");//添加子集
        this.auth.viewState = this.$hasAuthFor("api/configuration/updateConfigViewState");//启用禁用

    }
};