export const endEnum = [
    { name: "PC", value: 1 },
    { name: "小程序", value: 2 },
    { name: "APP", value: 4 },
    { name: "WAP", value: 3 }
]

// 函数创建一个空的菜单数据
export function initialPB() {
    return {
        id: '',//
        name: '',//名称
        value: '',//编码
        type: 0,//类型
        sequence: '99',//排序
        content: '',//备注
        parentName: '',

    }
}