<!-- 添加、编辑字典项 -->
<template>
    <el-dialog class="dictionary-edit"
        :visible="viewIsReady"
        @close="handleClose"
        :title="titleText"
        width="500px">
        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="100px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item label="类型" prop="type">
                <el-select v-model="PB.type" :disabled="isaddchild||isUpdate"
                    class="width3">
                    <el-option
                        v-for="e in endEnum"
                        :key="e.value"
                        :label="e.name"
                        :value="e.value"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="父类名称" prop="parentName" v-if="isUpdate && PB.parent_id">
                 <el-input v-model.trim="PB.parentName" disabled readonly=""
                    class="width3" maxlength="50"></el-input>
            </el-form-item>

            <el-form-item label="字典编码" prop="value">
                 <el-input v-model.trim="PB.value"
                    class="width3" type="number" oninput="if(value.length>50)value=value.slice(0,50)" ></el-input>
            </el-form-item>

            <el-form-item label="名称" prop="name">
                <el-input v-model.trim="PB.name"
                    class="width3" maxlength="50"></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="sequence" class="wrap">
                <el-input v-model.number="PB.sequence"
                    class="width3" maxlength="20"></el-input>
                <span class="hint">数字越大显示越靠前</span>
            </el-form-item>
            <el-form-item label="备注" prop="content">
                <el-input v-model="PB.content"
                    type="textarea" rows="5"
                    class="width3" maxlength="100"></el-input>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" v-if="!isaddchild" type="primary">保存</el-button>
            <el-button @click="handleSaveChild" v-if="isaddchild" type="primary">保存</el-button>

        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'MenuEdit',
    components: {},
    props: ["item","isaddchild", "levelEnum", "endEnum"],

    data () {
        return {
            viewIsReady: false,
            PB: {
                id:'',
                type: "",
                parentName: '',
                parent_id: '',
                value: "",
                name: "",
                sequence: '99',
                content: "",

            },

            // 验证规则
            rules: {
                type :{ required: true,"pattern": /^[1-99999]*$/,"message": "请选择类型",},
                value      : { required: true, message: "请输入编码",   trigger: "blur" },
                name : { required: true, message: "请输入名称", trigger: "blur" },
            },  
            parentEnum: []
        }
    },

    computed: {
        isUpdate () {
            return !!this.item?.id
        },
        titleText () {
            if(this.isUpdate){
                if(this.isaddchild){
                    return '添加子集'
                }else{
                    return '编辑数据字典'
                }
            }else{
                return '新增数据字典'
            }
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                if(!this.isaddchild){
                    for (let key in item) {
                        const _key = key.toCamel();
                        if (item[key] != null) this.PB[_key] = item[key];
                    }
                }
                if(this.isaddchild){
                    this.PB.type = item.type
                    this.PB.parentName = item.name
                    this.PB.parent_id = item.id
                    this.PB.id = ''
                }

                this.viewIsReady = true;
                setTimeout(() => {
                    this.$refs.form.clearValidate();
                }, 2);
            }
        }
    },

    methods: {
        // 添加子集
        async handleSaveChild () {
            const loadingRef = this.$loading({
                target: ".dictionary-edit .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const {name, value, type, parent_id,sequence, content} = this.PB;
                const res = await this.$axios({
                    url: "/api/configuration/addChildConfig",
                    method: "post",
                    data: {
                       name, value, type ,sequence, content,
                       parentId:parent_id
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;

            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },
        async handleSave () {
            const loadingRef = this.$loading({
                target: ".dictionary-edit .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const {name, value, type, sequence, content,id} = this.PB;
                const res = await this.$axios({
                    url: "/api/configuration/saveConfiguration",
                    method: "post",
                    data: {
                       name, value, type, sequence, content,id
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;

            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        handleClose () {
            this.viewIsReady = false;
            this.PB = {
                type: "",
                parentName: '',
                value: "",
                name: "",
                sequence: 99,
                content: "",
                id:'',
            };
            this.$refs.form.clearValidate();
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .hint {
        color: orangered !important;
    }
</style>